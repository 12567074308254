<template>
  <div>
    <el-card shadow="always">
      <el-button size="mini" @click="dialogFormVisible = true">添加地址</el-button>
      <el-table ref="address" fit h :data="addressData" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="receive_name" label="姓名" min-width="30"> </el-table-column>
        <el-table-column prop="phone_number" label="手机号" min-width="35"> </el-table-column>
        <el-table-column prop="detail_address" label="详细地址" min-width="100"> </el-table-column>
        <el-table-column prop="default_status" show-overflow-tooltip label="默认地址" min-width="30">
          <template slot-scope="scope">
            <el-tag :type="formatTagType(scope.row.default_status)">{{ scope.row.default_status === 0 ? '否' : '是' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="
                editDialogFormVisible = true
                handleEdit(scope.$index, scope.row)
              "
              >修改</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="
                centerDialogVisible = true
                handleDelete(scope.$index, scope.row)
              "
              >删除</el-button
            >
            <el-button type="primary" size="mini" @click="setToDefaultAddress(scope.$index, scope.row)">设为默认地址</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!--新增地址弹框-->
    <el-dialog title="添加地址" :visible.sync="dialogFormVisible" width="50%">
      <div>
        <el-row :gutter="20">
          <el-form ref="elForm" :model="addFormData" :rules="rules" size="mini" label-width="99px">
            <el-col :span="16">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="姓名" prop="receive_name">
                    <el-input v-model="addFormData.receive_name" placeholder="请输入姓名" clearable :style="{ width: '100%' }"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="手机号" prop="phone_number">
                    <el-input v-model="addFormData.phone_number" maxlength="11" placeholder="请输入手机号" clearable :style="{ width: '100%' }"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="详细地址" prop="detail_address">
                    <el-input
                      v-model="addFormData.detail_address"
                      type="textarea"
                      placeholder="请输入详细地址"
                      show-word-limit
                      :autosize="{ minRows: 1, maxRows: 10 }"
                      :style="{ width: '100%' }"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item prop="default_status">
                    <el-checkbox v-model="addChecked">设置为默认地址</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              <el-form-item size="large">
                <el-button type="primary" @click="submitForm">提交</el-button>
                <el-button @click="resetForm">重置</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-dialog>
    <!--编辑地址弹框-->
    <el-dialog title="编辑地址" :visible.sync="editDialogFormVisible" width="50%">
      <div>
        <el-row :gutter="20">
          <el-form ref="editElForm" :model="editFormData" :rules="rules" size="mini" label-width="99px">
            <el-col :span="16">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="姓名" prop="receive_name">
                    <el-input v-model="editFormData.receive_name" placeholder="请输入姓名" clearable :style="{ width: '100%' }"> </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="手机号" prop="phone_number">
                    <el-input v-model="editFormData.phone_number" placeholder="请输入手机号" maxlength="11" clearable :style="{ width: '100%' }"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="详细地址" prop="detail_address">
                    <el-input
                      v-model="editFormData.detail_address"
                      type="textarea"
                      placeholder="请输入详细地址"
                      show-word-limit
                      :autosize="{ minRows: 1, maxRows: 10 }"
                      :style="{ width: '100%' }"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item prop="default_status">
                    <el-checkbox v-model="editChecked">设置为默认地址</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              <el-form-item size="large">
                <el-button type="primary" @click="editSubmitForm">提交</el-button>
                <el-button @click="editResetForm">重置</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-dialog>
    <!--删除地址弹框-->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="20%" center>
      <span>确认删除该地址吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            centerDialogVisible = false
            deleteAddress()
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Address',
  data() {
    return {
      delete_address_id: '',
      addChecked: true,
      editChecked: false,
      dialogFormVisible: false,
      centerDialogVisible: false,
      editDialogFormVisible: false,
      addressData: [],
      editFormData: {
        receive_name: undefined,
        phone_number: undefined,
        detail_address: '',
        default_status: false
      },
      addFormData: {
        receive_name: undefined,
        phone_number: undefined,
        detail_address: '',
        default_status: false
      },
      rules: {
        receive_name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        phone_number: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        detail_address: [
          {
            required: true,
            message: '请输入详细地址',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getAddressList()
  },
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (valid) {
          console.log(this.addChecked)
          if (this.addChecked === true) {
            this.addFormData.default_status = 1
          } else {
            this.addFormData.default_status = 0
          }
          this.$api.address.addAddress(this.addFormData).then(res => {
            if (res.data.code === 200) {
              this.$message.success({
                message: '添加地址成功',
                center: true,
                duration: 2000
              })
              this.getAddressList()
            } else {
              this.$message.error({
                message: res.data.msg,
                center: true,
                duration: 5000
              })
            }
            this.dialogFormVisible = false
          })
        }
      })
    },
    editSubmitForm() {
      this.$refs['editElForm'].validate(valid => {
        if (valid) {
          if (this.editChecked === true) {
            this.editFormData.default_status = 1
          } else {
            this.editFormData.default_status = 0
          }
          this.$api.address.editAddress(this.editFormData).then(res => {
            if (res.data.code === 200) {
              this.$message.success({
                message: '修改地址成功',
                center: true,
                duration: 2000
              })
              this.getAddressList()
            } else {
              this.$message.error({
                message: res.data.msg,
                center: true,
                duration: 5000
              })
            }
            this.editDialogFormVisible = false
          })
        }
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
    editResetForm() {
      this.$refs['editElForm'].resetFields()
    },
    formatTagType(state) {
      if (state === 0) {
        return 'info'
      }
      return 'success'
    },
    getAddressList() {
      this.$api.address.getAddressList().then(res => {
        this.addressData = res.data.data
      })
    },
    handleEdit(index, row) {
      this.$api.address.getAddressById({ id: row.id }).then(res => {
        this.editFormData.id = res.data.data.id
        this.editFormData.receive_name = res.data.data.receive_name
        this.editFormData.detail_address = res.data.data.detail_address
        this.editFormData.phone_number = res.data.data.phone_number
        this.editFormData.default_status = res.data.data.default_status
        this.editChecked = this.editFormData.default_status === 1
      })
    },
    handleDelete(index, row) {
      this.delete_address_id = row.id
    },
    deleteAddress() {
      this.$api.address.deleteAddress({ id: this.delete_address_id }).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: '成功',
            center: true,
            duration: 2000
          })
          this.getAddressList()
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    },
    setToDefaultAddress(index, row) {
      this.editFormData.phone_number = row.phone_number
      this.editFormData.detail_address = row.detail_address
      this.editFormData.id = row.id
      this.editFormData.receive_name = row.receive_name
      this.editFormData.default_status = 1
      this.$api.address.editAddress(this.editFormData).then(res => {
        if (res.data.code === 200) {
          this.$message.success({
            message: '设置默认地址成功',
            center: true,
            duration: 2000
          })
          this.getAddressList()
        } else {
          this.$message.error({
            message: res.data.msg,
            center: true,
            duration: 5000
          })
        }
      })
    }
  }
}
</script>

<style scoped></style>
